import React from "react";
import PropTypes from "prop-types";
import { Alert, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const AlertMessage = styled(Alert)({
  width: "100%",
});

const TextoAlerta = styled(Box)({
  font: "Roboto",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "20.02px",
  letterSpacing: "0.17px",
  marginTop: 2,
});

const Alerta = ({ dadosAlerta, setMostrarAlerta }) => {
  const fecharAlerta = () => {
    setMostrarAlerta(false);
  };

  return (
    <AlertMessage severity={dadosAlerta?.severity} onClose={fecharAlerta} variant="outlined">
      <TextoAlerta>{dadosAlerta?.texto}</TextoAlerta>
    </AlertMessage>
  );
};

Alerta.propTypes = {
  setMostrarAlerta: PropTypes.func,
  dadosAlerta: PropTypes.object,
};

export default Alerta;
