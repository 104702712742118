import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Axios from "axios";
import env from "../src/../../environment";

import { useQueryParams } from "../../hooks";
import routes from "../../_common/components/MainRoutes/constantes";
import {
  INFOS_USUARIO,
  JWT_TOKEN,
  REFRESH_TOKEN,
  DASHBOARD_PERIODO_PADRAO,
} from "../../_common/utils/storage/constantesStorage";
import localStorageManager from "../../_common/utils/storage";
import {
  requestInterceptor,
  requestInterceptorError,
  responseInterceptor,
  errorResponseInterceptor,
} from "../../_common/utils/api/interceptors";
import Login from "../login/Login";
import errorsAuth from "../../_common/utils/auth/errorsAuth";
import { setErrorMessage, clearErrorMessage } from "../../store/reducers/login/loginActions";
import { Loading } from "../../_common";
import { getErrorMessage } from "../../_common/utils/string";
import { AddLogDataDog } from "../../_common/utils/startUpApplication/analyticsProvider/datadogBrowserRum";
import { LocalLog, StatusTypeLogDataDog } from "../../_common/utils/startUpApplication/analyticsProvider/constantesLog";

const LoginSSO = () => {
  const { hash } = useQueryParams();
  const dispatch = useDispatch();
  const [showLogin, setShowLogin] = useState(false);
  const [loading, setLoading] = useState(true);

  const obterDescricaoError = error => {
    const enumKey = Object.keys(errorsAuth).find(
      key => String(key).toLowerCase() === String(error?.response?.data?.ExceptionMessage).toLowerCase()
    );
    let descricaoError = "";
    if (enumKey) {
      descricaoError = errorsAuth[(error?.response?.data?.ExceptionMessage)];
    } else {
      descricaoError = getErrorMessage(error);
    }
    return descricaoError;
  };

  useEffect(() => {
    async function fetchData() {
      if (hash == null) return;
      const baseUrl = env.REACT_APP_BASE_URL;
      try {
        const response = await Axios.post(`${baseUrl}/api/pipeline/UsuarioPublico/SSOSignIn`, null, {
          params: {
            hash,
          },
        });
        const { jwt, refreshToken, refreshTokenDataExpiracao, dashboardPeriodoPadrao } = response.data;
        localStorageManager.setItem(JWT_TOKEN, jwt);
        localStorageManager.setItem(INFOS_USUARIO, response.data);
        localStorageManager.setItem(REFRESH_TOKEN, {
          RefreshToken: refreshToken,
          DataExpiracao: refreshTokenDataExpiracao,
        });
        localStorageManager.setItem(DASHBOARD_PERIODO_PADRAO, dashboardPeriodoPadrao);
        Axios.interceptors.response.use(responseInterceptor, errorResponseInterceptor);
        Axios.interceptors.request.use(requestInterceptor, requestInterceptorError);

        const message = { email: response?.data?.email };
        AddLogDataDog(`${LocalLog.LOGIN_SSO}_Success`, message, StatusTypeLogDataDog.INFO);

        setLoading(false);
        window.location.href = `${routes.LEAD_OPORTUNIDADE_BASE_DE_LEADS_FUNIL.route}`;
      } catch (error) {
        setLoading(false);
        const descricaoError = obterDescricaoError(error);

        const message = { hashSSO: hash, response: error?.response };
        AddLogDataDog(`${LocalLog.LOGIN_SSO}_Failed`, message, StatusTypeLogDataDog.ERROR);

        dispatch(setErrorMessage(descricaoError));
        setShowLogin(true);
      }
    }
    fetchData();
    return () => {
      dispatch(clearErrorMessage());
    };
  }, []);

  return (
    <>
      {loading && <Loading isLoading={loading} />}
      {showLogin && <Login />}
    </>
  );
};

export default LoginSSO;
